<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :totalCount="totalCount"
            @rowSelected="dynamic_target"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :column_meta="columnMeta"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'form_data';

    import ChirpList from '@/components/general/list/ChirpList';
    import qs from 'qs';

    export default {
        name: 'Forms',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                loading: true,
                loadingTable: false,
                useOverride: false,
                columnMeta: [
                    { field: 'active', hide: 1 },
                    { field: 'user_id', hide: 1 },
                    { field: 'client_id', hide: 1 },
                    { field: 'page_id', hide: 1 },
                    { field: 'signature_ids', hide: 1 },
                    { field: 'filled_by_client', headerText: 'Filled by Client' },
                    { field: 'locked', hide: 1 },
                    { field: 'email', hide: 1 },
                    { field: 'first_name', headerText: 'First Name' },
                    { field: 'last_name', headerText: 'Last Name' },
                    { field: 'page', hide: 1 },
                    { field: 'component_name', hide: 1 },
                    { field: 'page_title', headerText: 'Form Name' }
                ],
                criteriaProps: {}
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                if(this.$route.query.storeKey){
                    return this.$route.query.storeKey;
                }
                else if(this.pageMeta?.page){
                    return this.pageMeta.page;
                }
                else{
                    return 'forms';
                }
            }
        },
        methods: {
            async load_data() {
                const body = {
                    criteria: this.criteria
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows;

                this.totalCount = res.data.total_count;
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                let { override, overrideFilter, storeKey } = this.$route.query ?? {};
                if(override && overrideFilter && storeKey){
                    this.useOverride = true;
                    overrideFilter = qs.parse(overrideFilter);
                    this.overrideFilter = overrideFilter;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: storeKey,
                        criteria: this.overrideFilter
                    })
                }
                else if (this.overrideFilter && !override) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }
                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            async dynamic_target(row) {
                this.$router.push({ name: row.component_name, params: { record_id: row.id } });
            },
            handleCancelOverride() {
                this.useOverride = false;
            }
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        },
        async created() {
            await this.init();
        }
    };
</script>
